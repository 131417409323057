<template>
  <div class="static-page">

    <div class="static-page__hero">
      <div class="static-page__hero-backdrop">
        <img class="static-page__hero-image" src="assets/images/alpitour/statics/per-aziende-hero.jpg" alt="per le aziende hero" />
      </div>
      <div class="static-page__hero-content">
        <b-container class="d-flex">
          <vb-icon :name="'symbol_vv'" />
          <div class="static-page__hero-heading">
            <h1 class="vb-heading" v-html="'<span>I nostri servizi business</span>'" />
            <p class="text" v-html="'<span>Online travel booking, la nuova frontiera per il tuo loyalty e il tuo promo&amp;incentive</span>'" />
          </div>
        </b-container>
      </div>
    </div>

    <section class="section">
      <b-container>
        <h2 class="vb-heading h1" v-html="'<span>Le soluzioni business di ' + site.brand + ' ' + site.payoff + ' consentono alla tua azienda di proporre a clienti e dipendenti viaggi che rispecchiano i valori del tuo brand</span>'" />
        <b-row>
          <b-col class="mt-5" sm="12" lg="4">
            <vb-card :card="{
                image: '/assets/images/alpitour/statics/per-aziende-promo-concorsi.jpg',
                title: 'Promo e concorsi:',
                text: 'Soluzioni di travel booking online integrate alla tua piattaforma di instant win o ai siti delle tue campagne marketing, soluzioni semplici e dinamiche con una user experience in linea con la tua brand identity',
              }" />
          </b-col>
          <b-col class="mt-5" sm="12" lg="4">
            <vb-card :card="{
                image: '/assets/images/alpitour/statics/per-aziende-incentive-trade.jpg',
                title: 'Incentive al trade:',
                text: 'Soluzioni di credito virtuale che l’utente può utilizzare in modo autonomo e flessibile su un sito allineato alla tua brand identity e ai tuoi valori',
              }" />
          </b-col>
          <b-col class="mt-5" sm="12" lg="4">
            <vb-card :card="{
                image: '/assets/images/alpitour/statics/per-aziende-piani-loyalty.jpg',
                title: 'Piani loyalty:',
                text: 'Crediti travel per premiare i clienti fedeli, disponibilità immediata e flessibile che il cliente può gestire in autonomia. ' + site.brand + site.payoff + ' permette la realizzazione di piani a punti convertibili in ogni momento tramite un’integrazione di facile gestione aziendale e altamente user friendly',
              }" />
          </b-col>
          <b-col class="mt-5" sm="12" lg="4" offset-lg="2">
            <vb-card :card="{
                image: '/assets/images/alpitour/statics/per-aziende-regali-aziendali.jpg',
                title: 'Regali aziendali:',
                text: 'Flessibili e immediati, regali che il beneficiario può decidere di utilizzare con pieno controllo sulla scelta finale del viaggio. Brandizzazione del sito web di booking, opzione gift o semplice credito travel digitale integrabile con carta di credito all’insegna della totale flessibilità',
              }" />
          </b-col>
          <b-col class="mt-5" sm="12" lg="4">
            <vb-card :card="{
                image: '/assets/images/alpitour/statics/per-aziende-welfare-aziendale.jpg',
                title: 'Welfare aziendale:',
                text: 'Le soluzioni di travel booking ' + site.brand + ' ' + site.payoff + ' per il welfare aziendale consentono una gestione trasparente e automatizzata del credito. In un’epoca in cui i viaggi sono tornati tra le prestazioni di welfare più richieste dai dipendenti, la completa integrazione con i maggiori portali permette a ' + site.brand + ' di essere una scelta davvero vincente.',
              }" />
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="section">
      <b-container>
        <h3 class="mb-4" v-html="site.brand + ' ' + site.payoff + ' <span class=\'text-secondary\'>non</span> è solo una piattaforma<br> di travel booking online.'" />
        <p v-html="'Agli standard tecnologici di classe internazionale aggiunge:'" />

        <b-row class="pt-5">
          <b-col class="mb-2" sm="12" lg="4" offset-lg="2">
            <div class="static-page__amenities border-0" no-body>
              <vb-icon :name="'lamp'" color="fff" :size=60 />
              <div class="static-page__amenities-body">
                <h4 class="static-page__amenities-title h5" v-html="site.brand + ' ' + site.payoff + ' è flessibilità'" />
                <div class="static-page__amenities-text" v-html="'Flessibilità nell’elaborare soluzioni adatte alle tue esigenze di business.'" />
              </div>
            </div>
          </b-col>
          <b-col class="mb-2" sm="12" lg="4">
            <div class="static-page__amenities border-0" no-body>
              <vb-icon :name="'call_center'" color="fff" :size=60 />
              <div class="static-page__amenities-body">
                <h4 class="static-page__amenities-title h5" v-html="site.brand + ' ' + site.payoff + ' è assistenza'" />
                <div class="static-page__amenities-text" v-html="'Un servizio di assistenza all’utenza unico nel settore del travel online, elemento essenziale di qualsiasi applicazione del travel alle varie funzioni e attività aziendali.'" />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="section">
      <div class="static-page__form">
        <b-container>
          <form-contatti
              :formTitle="title_request_form"
              :formSubTitle="subtitle_request_form"
              :label="'form-contatti-business'"
              :recipient="config.site.emailSupport"
          />
      </b-container>
      </div>
    </section>

  </div>
</template>


<script>
import Vue from "vue";
import Card from '@library/blocks/card.vue'
import FormContatti from "@components/blocks/form-contatti.vue";

export default {
  name: 'per-le-aziende',
  components:{
    'vb-card': Card,
    'form-contatti': FormContatti,
  },
  props: {
  },
  created() {
  },
  data () {
    return {
      config: Vue.prototype.$config,
      site: Vue.prototype.$config.site,
      title_request_form: 'Hai bisogno di <span class=\'text-secondary\'>maggiori informazioni?</span> Contattaci!',
      subtitle_request_form: 'Compila il seguente form oppure scrivi una mail a <a href=\'mailto:' + Vue.prototype.$config.site.emailSupport + '\'>' + Vue.prototype.$config.site.emailSupport + '</a>',
    }
  },
  computed: {
  },
}
</script>


<style lang="scss" scoped>

</style>